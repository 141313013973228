/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.footer {
	border-top: 1px solid rgba(152,166,173,.2);
	bottom: 0;
	padding: 20px 30px 20px;
	right: 0;
	color: #98a6ad;
	left: 0px;
	text-align: center
}

	.footer .footer-links a {
		color: #98a6ad;
		margin-left: 1.5rem;
		-webkit-transition: all .4s;
		transition: all .4s
	}

		.footer .footer-links a:hover {
			color: #313a46
		}

		.footer .footer-links a:first-of-type {
			margin-left: 0
		}

@media (max-width:767.98px) {
	.footer {
		left: 0 !important;
		text-align: center
	}
}

#forecastMap{
	height: 350px;
}

.img-attribution-container{
	background:rgba(255, 255, 255, 0.7);
}

.img-attribution{
	padding: 0 5px;
	font-size: 11px;
	-webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.img-attribution a {
	color:#0078A8;
}

.doughnut-rating-overlay{
    position: absolute;
    top: 30%;
    left: 12%;
}

.view-forecast-button{
	position: absolute;
	right:0;
	top:0;
}